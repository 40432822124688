/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Icon, Input, message, Modal, Row, Select, Switch, Table, Tooltip, Form, Button, AutoComplete } from 'antd'
import { FetchingState, fetchWithState } from '../../common-components'
import {
    Categories,
    SubCategory,
    InitSubCategory,
    getAllSubCategory,
    addSubCategory,
    updateSubCategory,
    deleteSubCategory,
    getAllCategory,
    getAllItems
    // getAllCategoryNoRedux
} from '../../incident-management/category'

import { connect } from 'react-redux'
import { StoreState } from '../../store'
import { FormComponentProps } from 'antd/es/form'

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.categories,
        subCategories: state.subCategories
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategory: () => Promise<number>;
    getAllSubCategory: () => Promise<number>;
    addSubCategory: (subCategory: InitSubCategory) => Promise<number>;
    updateSubCategory: (subCategory: InitSubCategory) => Promise<number>;
    deleteSubCategory: (id: number) => Promise<number>;
    getAllItems: () => Promise<number>;
}

interface Params {
    fetchSubCategory: boolean
    setFetchSubCategory: Function
    setFetchItems: Function
}

type Props = StateProps & DispatchProps & FormComponentProps & Params
const { Search } = Input
const { Option } = Select
const { confirm } = Modal
const SubCategoriesTab: React.FC<Props> = (props: Props) => {
    const [fetchingState, setFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [page, setPage] = useState<number>(1)
    const [searchType, setSearchType] = useState<string>('0')
    const pageSize: number = 10
    const [sortInfo, setSortInfo] = useState<any>({})
    const [activateFilter, setActivateFilter] = useState<boolean[]>([true, false])
    const [value] = useState<string>('')
    const [filterValue, setFilterValue] = useState<boolean[]>([true, false])
    const { getFieldDecorator, resetFields, validateFields, setFields, getFieldValue } = props.form
    const [catAll, setCatAll] = useState<Categories[]>([])
    const [dataSource, setDataSource] = useState<SubCategory[]>()
    const [isDisableSubCatField, setIsDisableSubCatField] = useState<boolean>(true)
    const [editSubCat, setEditSubCat] = useState<SubCategory | undefined>(undefined)
    const [loadActive, setLoadActive] = useState<SubCategory | undefined>(undefined)
    const [editIsLoading, setEditIsLoading] = useState<boolean>(false)
    // const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isRequires, setIsRequires] = useState<boolean>(false)
    const fetch = () => {
        if (fetchingState === FetchingState.Succeed) {
            fetchWithState(
                FetchingState.NotStarted,
                setFetchingState,
                () => props.getAllSubCategory().finally(() => {
                    props.setFetchSubCategory(false)
                })
            )
        } else {
            fetchWithState(
                FetchingState.NotStarted,
                setFetchingState,
                () => props.getAllSubCategory().finally(() => {
                    props.setFetchSubCategory(false)
                })
            )
        }
    }

    const fetchCategory = () => {
        props.getAllCategory().finally(() => setFetchingState(FetchingState.Succeed))
    }

    useEffect(() => {
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.subCategories.length > 0) {
            setDataSource(props.subCategories)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.subCategories])

    useEffect(() => {
        if (props.fetchSubCategory) {
            fetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fetchSubCategory])

    useEffect(() => {
        if (props.categories.length > 0) {
            setCatAll(props.categories)
        } else {
            fetchCategory()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories])

    useEffect(() => {
        if (activateFilter.includes(true) && activateFilter.includes(false)) {
            setDataSource(props.subCategories)
        } else if (activateFilter.includes(true)) {
            setDataSource(props.subCategories?.filter(subCategories => subCategories.active === true))
        } else if (activateFilter.includes(false)) {
            setDataSource(props.subCategories?.filter(subCategories => subCategories.active === false))
        } else {
            setDataSource(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activateFilter])

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('err', e)
        if (getFieldValue('category') === undefined) {
            setFields({ category: { errors: [new Error('Category Name is required')] } })
            return
        }
        if (getFieldValue('subCat') === undefined && !isDisableSubCatField) {
            setFields({ subCat: { errors: [new Error('Subcategory Name is required')] } })
            return
        }
        validateFields(['category', 'subCat'], (err, values) => {
            // values.category = values.category ? values.category.replace(/ +/g, ' ').trim() : undefined
            values.subCat = values.subCat ? values.subCat.replace(/ +/g, ' ').trim() : undefined
            if (values.subCat?.length <= 0) {
                setFields({ subCat: { errors: [new Error('Subcategory Name is required')] } })
                return
            }
            console.log('values.subCat ', values.subCat)
            if (!err) {
                const selectedCategory = props.categories.find(cat => cat.id?.toString() === values.category?.toString())
                console.log('values', values)
                const NewSubCategory: InitSubCategory = {
                    category: {
                        id: selectedCategory?.id!,
                        name: selectedCategory?.name!
                    },
                    name: values.subCat,
                    active: true
                }
                props.addSubCategory(NewSubCategory).then(() => {
                    message.success('You have successfully saved the data.')
                }).catch((err) => {
                    message.error(err)
                })
                resetFields()
                setIsDisableSubCatField(true)
                setIsRequires(false)
            }
        })
    }

    const handleEdit = (subCategory: SubCategory) => {
        const tempSubCat: SubCategory = {
            active: subCategory.active,
            category: subCategory.category,
            categoryId: subCategory.categoryId,
            createdBy: subCategory.createdBy,
            createdByDisplay: subCategory.createdByDisplay,
            createdById: subCategory.createdById,
            createdDatetime: subCategory.createdDatetime,
            id: subCategory.id,
            lastModifiedBy: subCategory.lastModifiedBy,
            lastModifiedByDisplay: subCategory.lastModifiedByDisplay,
            lastModifiedById: subCategory.lastModifiedById,
            subCategory: subCategory.subCategory
        }
        // setEditSubCat({ ...subCategory })
        setEditSubCat(tempSubCat)
    }

    const submitEdit = (e) => {
        e.preventDefault()
        if (editSubCat) {
            let isExists: Boolean = false
            validateFields(['editSubcat'], (err, values) => {
                if (!err) {
                    const initSubCategory: InitSubCategory = {
                        id: editSubCat.id,
                        category: {
                            id: editSubCat.categoryId,
                            name: editSubCat.category
                        },
                        name: values.editSubcat,
                        active: true
                    }
                    const filterSubCategory = props.subCategories?.filter((subCategory) => subCategory.id !== editSubCat.id)
                    if (filterSubCategory) {
                        (filterSubCategory || []).forEach((it) => {
                            if (it.categoryId?.toString() === initSubCategory.category.id?.toString() &&
                            it.subCategory.toUpperCase().trim() === initSubCategory.name.toUpperCase().trim()) {
                                if (it.id?.toString() === editSubCat.id?.toString()) {
                                    setEditSubCat(undefined)
                                    isExists = true
                                } else {
                                    message.error('The SubCatagories Name already exists. It must be unique. Please try another one.')
                                    console.log('invalid')
                                    isExists = true
                                }
                            }
                        })
                    }
                    if (!isExists) {
                        setEditIsLoading(true)
                        props.updateSubCategory(initSubCategory).then(() => {
                            message.success('The Update has finished successfully')
                            props.setFetchItems(true)
                        }).catch((e) => {
                            message.error(e.message)
                        }).finally(() => {
                            setEditSubCat(undefined)
                            setEditIsLoading(false)
                        })
                    }
                }
            })
        }
    }

    const getPage = (page: number) => {
        setPage(page)
    }

    const validateDropdown = (_: any, value: any, callback) => {
        validateFields(['subCat'])
        callback()
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        (props.subCategories || []).forEach((it) => {
            if (it.categoryId?.toString() === props.form.getFieldValue('category')?.toString() &&
                    it.subCategory.toUpperCase().trim() === props.form.getFieldValue('subCat').toUpperCase().trim()) {
                callback('The SubCatagories Name already exists. It must be unique. Please try another one.')
            }
        })
        if (typeof value === 'string' && value.trim().length > 0) {
            // eslint-disable-next-line
            const format = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (format.test(value)) {
                // eslint-disable-next-line
                callback('Field Label can' + 't contain any of the following characters ' + format)
            } else {
                if (pattern.test(value.trimEnd())) {
                    callback()
                } else {
                    callback('Default value is not a valid')
                }
            }
        } else {
            callback()
        }
    }

    const validateEditNameUniqueness = (_: any, value: any, callback) => {
        if (value) {
            if (typeof value === 'string' && value.trim().length > 0) {
                if (editSubCat) {
                    const filterSubCat = props.subCategories?.filter((it) => it.subCategory?.toUpperCase().trim() !== editSubCat.subCategory?.toUpperCase().trim())
                    if (filterSubCat) {
                        (filterSubCat || []).forEach((it) => {
                            if (it.categoryId?.toString() === props.form.getFieldValue('category')?.toString() && it.subCategory.toUpperCase().trim() === value.toUpperCase().trim()) {
                                callback('The SubCatagories Name already exists. It must be unique. Please try another one.')
                            }
                        })
                    }
                } else {
                    (props.subCategories || []).forEach((it) => {
                        if (it.categoryId?.toString() === props.form.getFieldValue('category')?.toString() && it.subCategory?.toUpperCase().trim() === value?.toUpperCase().trim()) {
                            callback('The SubCatagories Name already exists. It must be unique. Please try another one.')
                        }
                    })
                }
                // eslint-disable-next-line
                const format = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                const pattern = /[A-Za-z0-9ก-๙]+/
                if (format.test(value)) {
                    // eslint-disable-next-line
                    callback('Field Label can' + 't contain any of the following characters ' + format)
                } else {
                    if (pattern.test(value.trimEnd())) {
                        callback()
                    } else {
                        callback('Default value is not a valid')
                    }
                }
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const deleteCategory = (subcategories: SubCategory) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this Subcategory?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                if (subcategories.active) {
                    message.warning('Please Deactivate.')
                } else {
                    props.deleteSubCategory(subcategories.id!!).then(() => {
                        props.getAllItems()
                    }).catch(err => {
                        message.error(err)
                    })
                }
            },
            onCancel() {
            }
        })
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        getPage(page)
    }

    const handleChangeTypeSearch = (e) => {
        setSearchType(e)
    }

    const handleSearchValue = (value) => {
        if (searchType?.toString() === '0') {
            if (value.length?.toString() === '0') {
                if (props.subCategories) {
                    setDataSource(props.subCategories)
                }
            } else {
                const searchValue = props.subCategories.filter(subCategories =>
                    subCategories.category.toUpperCase().search(value.toUpperCase()) >= 0 ||
                    subCategories.subCategory.toUpperCase().search(value.toUpperCase()) >= 0
                )
                setDataSource(searchValue)
            }
        } else if (searchType?.toString() === '1') {
            if (value.length?.toString() === '0') {
                if (props.subCategories) {
                    setDataSource(props.subCategories)
                }
            } else {
                const searchValue = props.subCategories.filter(subCategories => subCategories.category.toUpperCase().search(value.toUpperCase()) >= 0)
                setDataSource(searchValue)
            }
        } else if (searchType?.toString() === '2') {
            const searchValue = props.subCategories.filter(subCategories =>
                subCategories.subCategory.toUpperCase().search(value.toUpperCase()) >= 0
            )
            setDataSource(searchValue)
        }
    }

    const toggleActiveness = (cat) => {
        const proceed = () => {
            setLoadActive(cat)
            props.updateSubCategory({ id: cat.id, active: !cat.active } as InitSubCategory)
                .catch((err) => {
                    if (err?.response?.status === 409) {
                        message.warning('This Incident is being used by Category.')
                    } else {
                        message.error(`Failed toggling Category activeness. ${err}`)
                    }
                })
                .finally(() => {
                    setLoadActive(undefined)
                })
        }

        if (!cat.active) {
            proceed()
        } else {
            try {
                confirm({
                    title: 'Are you sure?',
                    content: 'Do you confirm Deactivate this data?',
                    okText: 'OK',
                    okType: 'danger',
                    autoFocusButton: 'cancel',
                    cancelText: 'Cancel',
                    async onOk() {
                        proceed()
                    },
                    onCancel() {
                    }
                })
            } catch (error: any) {
                console.log(error)
            }
        }
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        setSortInfo(sorter)
    }

    const handleFilter = (value) => {
        setFilterValue(value)
        if (value) {
            if (value.length === 1) {
                if (value[0] === 'true' || value[0] === true) {
                    setActivateFilter([true])
                } else {
                    setActivateFilter([false])
                }
            } else if (value.length === 2) {
                setActivateFilter([true, false])
            } else {
                setActivateFilter([])
            }
            setPage(1)
        }
    }

    const renderFilter = () => {
        return (<>
            <Checkbox.Group style={{ width: '100%' }} onChange={handleFilter} value={filterValue}>
                <Row>
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={true}>Activate</Checkbox>
                    </Col>
                    <br />
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={false}>Deactivate</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </>)
    }

    const sorter = (a: string, b: string) => (a !== null && b !== null ? (a || '').localeCompare(b || '') : (b || '').localeCompare(a || ''))

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row: any) =>
                <span><Icon type="delete" onClick={() => deleteCategory(row)} /></span>
        }, {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row: any) => {
                if (editSubCat?.id?.toString() === row.id?.toString()) {
                    return <Form.Item><Button icon='save' style={{ border: 0, background: 'rgb(0,0,0,0)' }} htmlType="submit"></Button> </Form.Item>
                }
                return (
                    <span><Icon type="edit" onClick={() => handleEdit(row)} /> </span>
                )
            }
        },
        {
            title: 'Subcatagory',
            dataIndex: '',
            key: 'subCategory',
            sorter: (a, b) => sorter(a.subCategory, b.subCategory),
            sortOrder: sortInfo.columnKey === 'subCategory' && sortInfo.order,
            render: (row: SubCategory) => {
                if (editSubCat?.id?.toString() === row.id?.toString()) {
                    return <Form.Item>
                        {getFieldDecorator('editSubcat',
                            {
                                initialValue: row.subCategory,
                                rules: [{ required: true, message: 'Subcategory Name is required' },
                                    { max: 250, message: 'message max 250 characters' },
                                    { validator: validateEditNameUniqueness }
                                ]
                            })(
                            <AutoComplete
                                placeholder="Subcategory Name"
                                disabled={editIsLoading}
                                allowClear
                                // onSelect={(e) => handelSelectCat(e)}
                                filterOption={(inputValue, option) =>
                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        )}
                    </Form.Item>
                }
                return (
                    <>{row.subCategory}</>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            sorter: (a, b) => sorter(a.category, b.category),
            sortOrder: sortInfo.columnKey === 'category' && sortInfo.order
        },
        {
            title: 'Last Modified',
            dataIndex: '',
            key: '',
            width: '20%',
            render: (row: SubCategory) => {
                return (
                    <>
                        {row.lastModifiedDatetime?.length === 23 ? row.lastModifiedDatetime.substring(0, row.lastModifiedDatetime.length - 4) : row.lastModifiedDatetime}
                    </>
                )
            }
        },
        {
            title: '',
            dataIndex: 'active',
            key: 'active',
            width: '10%',
            filters: [
                {
                    text: 'Activate',
                    value: 'true'
                },
                {
                    text: 'Deactivate',
                    value: 'false'
                }
            ],
            filteredValue: filterValue,
            filterDropdown: renderFilter,
            render: (check, row) => {
                return (
                    <>
                        <Tooltip placement="bottom" title={row.active ? 'Deactivate' : 'Activate'}>
                            <Switch style={{ float: 'right' }} loading={loadActive?.id?.toString() === row.id?.toString()} checked={check}
                                onChange={() => toggleActiveness(row)} />
                        </Tooltip>
                    </>
                )
            }
        }
    ]

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row gutter={[10, 10]}>
                    <Col md={9} sm={24} xs={24}>
                        <Form.Item label={'Category Name'}>
                            {getFieldDecorator('category',
                                {
                                    initialValue: undefined,
                                    rules: [{ required: true, message: 'Category Name is required' },
                                    // { max: 250, message: 'message max 250 characters' },
                                        { validator: validateDropdown }
                                    ]
                                })(
                                <Select placeholder="Category Name" onChange={() => {
                                    setIsDisableSubCatField(false)
                                    setIsRequires(true)
                                }}>
                                    {catAll.map(cat => {
                                        if (cat.active) {
                                            return (<Option value={cat.id} key={cat.id}>{cat.name}</Option>)
                                        }
                                    })}
                                    {/* <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="disabled" disabled>
                                        Disabled
                                    </Option>
                                    <Option value="Yiminghe">yiminghe</Option> */}
                                </Select>
                                // <AutoComplete
                                //     dataSource={}
                                //     placeholder="Category Name"
                                //     // onSelect={(e) => handelSelectCat(e)}
                                //     // onChange={(e) => checkRequiredSubCat(e, 'Cat')}
                                //     // filterOption={(inputValue, option) =>
                                //     //     (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                //     // }
                                // />
                            )}
                        </Form.Item>
                    </Col>
                    <Col md={9} sm={24}>
                        <Form.Item label={'Subcategory Name'}>
                            {getFieldDecorator('subCat',
                                {
                                    initialValue: undefined,
                                    rules: [{ required: isRequires, message: 'Subcategory Name is required' },
                                        { max: 250, message: 'message max 250 characters' },
                                        { validator: validateNameUniqueness }
                                    ]
                                })(
                                <Input
                                    // dataSource={subCatName}
                                    placeholder="Subcategory Name"
                                    disabled={isDisableSubCatField}
                                    allowClear
                                    // onSelect={(e) => handelSelectSubCat(e)}
                                    // onChange={(e) => checkRequiredSubCat(e, 'subCat')}
                                    // filterOption={(inputValue, option) =>
                                    //     (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    // }
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset"
                                onClick={() => {
                                    resetFields()
                                    setFields({ subCat: undefined })
                                    setIsDisableSubCatField(true)
                                }}>Cancel</Button>
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                                htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <br />
            <div style={{ overflow: window.innerWidth <= 768 ? 'auto' : 'none' }}>
                {/* <TableCategory getAllCategory={getAllCategory} handleOnClickInOtherPage={handleOnClickInOtherPage} getDataToEdit={getDataToEdit} getPage={getPage} /> */}
                <div>
                    <div style={{ float: 'right' }}>
                        <Row gutter={16}>
                            <Col span={7}>
                                <Select defaultValue={searchType} style={{ width: 120 }}
                                    onChange={(e) => handleChangeTypeSearch(e)}>
                                    <Option value="0">All</Option>
                                    <Option value="1">Category</Option>
                                    <Option value="2">Subcategory</Option>
                                </Select>
                            </Col>
                            <Col span={7}>
                                <Search
                                    defaultValue={value}
                                    placeholder="Can search Category or Subcategory"
                                    onSearch={value => handleSearchValue(value)}
                                    id='search_subCategory'
                                    allowClear
                                    style={{ width: 300 }}
                                />
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <br />
                    <Form onSubmit={submitEdit}>
                        <Table
                            columns={columns}
                            dataSource={dataSource || []}
                            // expandedRowRender={expandedRowRender}
                            // defaultExpandAllRows={true}
                            pagination={{
                                pageSize: pageSize,
                                current: page,
                                total: dataSource?.length || 0,
                                onChange: (event) => {
                                    handleOnClickPageNumber(event)
                                }
                            }}
                            rowKey="id"
                            onChange={handleChangeTable}
                            loading={fetchingState !== 2}
                            locale={{
                                filterConfirm: '',
                                filterReset: ''
                            }}
                            expandIconColumnIndex={0}
                            expandIconAsCell={false}
                            scroll={{ x: 768 }}

                        />
                    </Form>
                </div>
            </div>
        </>
    )
}

const SubCategoriesTabFrom = Form.create<Props>()(SubCategoriesTab)

export default connect(mapStateToProps, {
    getAllSubCategory, addSubCategory, updateSubCategory, deleteSubCategory, getAllCategory, getAllItems
})(SubCategoriesTabFrom)
