import React from 'react'
import {Breadcrumb, Tabs} from 'antd'
import WrappedNormalDashboardForm from './IncidentDash'
import SatWrappedNormalDashboardForm from './CustomerSatDash'
import {FormComponentProps} from 'antd/lib/form'
// import KnowledgeDash from './KnowledgeDash'

type Props = FormComponentProps
const Home: React.FC<Props> = (props: Props) => {
    const { TabPane } = Tabs

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Tabs defaultActiveKey="1">
                <TabPane tab="Ticket" key="1">
                    <WrappedNormalDashboardForm />
                </TabPane>
                <TabPane tab="CSAT" key="2">
                    <SatWrappedNormalDashboardForm />
                </TabPane>
                {/* <TabPane tab="Knowledge" key ="3">
                    <KnowledgeDash />
                </TabPane> */}
            </Tabs>
        </div>
    )
}
export default Home
