/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-prototype-builtins */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {StoreState} from '../../store'

import {Breadcrumb, Button, Card, Checkbox, Col, Form, InputNumber, message, Modal, Row, Select, Tabs } from 'antd'
import {FormComponentProps} from 'antd/lib/form'

import {RequestBodyGeneralIncidentSetting} from './model'
import {createGeneralIncidentSetting, getAllGeneralIncidentSetting} from './service'
import {Link, Redirect} from 'react-router-dom'
import {SelectValue} from 'antd/es/select'
import TemplateTicketSetting from './TemplateTicketSetting'

const mapStateToProps = (state: StoreState) => {
    return {
        generalIncidentSetting: state.generalIncidentSetting
    }
}

interface DispatchProps {
    getAllGeneralIncidentSetting: () => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>

interface OwnProps {
    editData?: any
}

type Props = StateProps & OwnProps & FormComponentProps & DispatchProps

const {confirm} = Modal
const { TabPane } = Tabs
let prevTicketTypeSelectedSize = 0
let prevChannelSelectedSize = 0
const GeneralIncidentSettingForm: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, setFieldsValue, validateFields, setFields} = props.form

    const [isRedirect, setIsRedirect] = useState(false)
    const [isAutoClose, setIsAutoClose] = useState(false)
    const [autoCloseDay, setAutoCloseDay] = useState<number | undefined>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [listOfTicketType, setListOfTicketType] = useState<any[]>([])
    const [listOfChannel, setListOfChannel] = useState<any[]>([])
    const [key, setkey] = useState('1')

    const [id, setId] = useState<string>()

    useEffect(() => {
        fetchGeneralIncidentSetting()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        initialGeneralIncidentSetting()
        setId(props.generalIncidentSetting.id?.toString())
        console.log(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.generalIncidentSetting])

    const fetchGeneralIncidentSetting = () => {
        props.getAllGeneralIncidentSetting().catch(() => message.error('Failed get all general ticket setting'))
    }

    const initialGeneralIncidentSetting = () => {
        const listOfTicketType: RequestBodyGeneralIncidentSetting[] = []
        const listOfChannel: RequestBodyGeneralIncidentSetting[] = []
        setFieldsValue({
            ticketTypes: props.generalIncidentSetting.ticketTypes?.map((item) => item.value),
            channels: props.generalIncidentSetting.channels?.map((item) => item.value)
        })
        props.generalIncidentSetting.ticketTypes?.map(ticketType => {
            const obj = {
                id: ticketType.id,
                settingType: 'ticketType',
                value: ticketType.value,
                active: true
            }
            listOfTicketType.push(obj)
        })
        props.generalIncidentSetting.channels?.map(ticketType => {
            const obj = {
                id: ticketType.id,
                settingType: 'channel',
                value: ticketType.value,
                active: true
            }
            listOfChannel.push(obj)
        })
        prevTicketTypeSelectedSize = listOfTicketType.length
        prevChannelSelectedSize = listOfChannel.length
        setListOfTicketType(listOfTicketType)
        setListOfChannel(listOfChannel)
        setIsAutoClose(props.generalIncidentSetting.isAutoClose!!)
        if (props.generalIncidentSetting.isAutoClose === true) {
            setAutoCloseDay(Number(props.generalIncidentSetting.autoCloseDay!!))
        } else {
            setAutoCloseDay(1)
        }
    }

    const modifyBodyBeforeCreate = (type: string, value: SelectValue) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false
        // eslint-disable-next-line camelcase
        const arr_lower = selectedValue.map(item => item?.toLowerCase().trim())
        const lastValue = selectedValue[selectedValue.length - 1]?.toLowerCase().trim()
        arr_lower.pop()
        if (arr_lower.includes(lastValue)) {
            selectedValue.pop()
        }
        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return
        }
        if (type === 'ticketType') {
            if (prevTicketTypeSelectedSize < selectedValue.length) {
                /* [insert ticket type] */
                modifyRequestBodyWhenInsert(type, selectedValue, listOfTicketType)
            } else {
                /* [delete ticket type] */
                modifyRequestBodyWhenDelete(listOfTicketType, value, setListOfTicketType)
            }
            prevTicketTypeSelectedSize = selectedValue.length
        } else {
            if (prevChannelSelectedSize < selectedValue.length) {
                /* [insert channel] */
                modifyRequestBodyWhenInsert(type, selectedValue, listOfChannel)
            } else {
                /* [delete channel] */
                modifyRequestBodyWhenDelete(listOfChannel, value, setListOfChannel)
            }
            prevChannelSelectedSize = selectedValue.length
        }
    }

    const modifyRequestBodyWhenInsert = (type: string, selectedValue, list) => {
        let result: boolean
        selectedValue.forEach((value) => {
            result = list.map(item => item.value).includes(value)
            if (!result) {
                const obj = {
                    settingType: type,
                    value: value,
                    active: true
                }
                list.push(obj)
            } else {
                /* insert exist name that active false update active to true */
                const index = list.findIndex(it => it.value === value)
                list[index].active = true
            }
        })
    }

    const modifyRequestBodyWhenDelete = (list: any[], selectedValue, setState: Function) => {
        list.forEach((it, index) => {
            const isNotDeleted = selectedValue.includes(it.value)
            if (isNotDeleted === false) {
                if (it.id) {
                    it.active = false
                    setState(list)
                } else {
                    list.splice(index, 1)
                    setState(list)
                }
            }
        })
    }

    const submitFrom = () => {
        validateFields(
            (error, value) => {
                let mergeTicketTypeAndChannel: any[] = []
                if (error) {
                    console.log(value)
                    return
                }
                setIsLoading(true)
                mergeTicketTypeAndChannel = listOfTicketType.concat(listOfChannel)
                console.log(autoCloseDay)
                const autoCloseObj = {
                    settingType: 'autoCloseDay',
                    value: autoCloseDay,
                    active: isAutoClose
                }
                mergeTicketTypeAndChannel.push(autoCloseObj)
                // Send to Backend for create
                createGeneralIncidentSetting(mergeTicketTypeAndChannel).then(() => {
                    message.success('You have Successfully save the data.')
                    setIsRedirect(true)
                    setIsLoading(false)
                }).catch((error) => {
                    setIsLoading(false)
                    if (error.status === 409) {
                        setFields({ticketTypes: {errors: [new Error(error.data.message)]}})
                    }
                    message.error(error.data.message)
                })
            })
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const validateDecimal = (rule: any, value: number, callback) => {
        if (value) {
            if (!Number.isInteger(value)) {
                callback('Not support Decimal')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const validateDepartment = (_: any, value: any, callback) => {
        if (value.length > 0) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            if (format.test(value[value.length - 1].charAt(0))) {
                // eslint-disable-next-line
                callback(`Enter more than 1 letter and can not contain any of the following characters ${format}`)
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const handelChangTab = (e) => {
        setkey(e)
    }

    return (
        <>
            {isRedirect ? (<Redirect to="/Setting"/>) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{key === '1' ? 'General Parameter' : 'Template Ticket Setting'} </Breadcrumb.Item>
            </Breadcrumb>
            <br/>
            <Tabs defaultActiveKey="1" activeKey={key} onChange={(e) => handelChangTab(e)}>
                <TabPane tab="General Parameter" key="1">
                    <Card style={{minHeight: '535px'}}>
                        <h3 className={'main-title'}>General Parameter</h3>
                        <br/>
                        <Form layout="vertical">
                            <Row gutter={32}>
                                <Col span={10}>
                                    <Form.Item label={'Ticket Types'}>
                                        {getFieldDecorator('ticketTypes', {
                                            rules: [{required: true, message: 'Ticket Types is required'}]
                                        })(
                                            // <Select mode="tags" style={{width: '100%'}} placeholder="Ticket Types"/>
                                            <Select mode="tags"
                                                placeholder="Ticket Types"
                                                style={{width: '100%'}}
                                                disabled
                                                onChange={(value => modifyBodyBeforeCreate('ticketType', value))}
                                            >
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col span={10}>
                                    <Form.Item label={'Channel'}>
                                        {getFieldDecorator('channels', {
                                            rules: [{required: true, message: 'Channels is required'}, {
                                                validator: validateDepartment
                                            }]
                                        })(
                                            <Select mode="tags"
                                                style={{width: '100%'}}
                                                placeholder="channels"
                                                onChange={(value => modifyBodyBeforeCreate('channel', value))}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <h3 className={'main-title'} style={{marginTop: '25px'}}>Auto Closed Setting</h3>
                            <Row style={{paddingLeft: '24px', marginTop: '20px'}}>
                                <Row style={{marginTop: '20px'}}>
                                    <Col span={24}>
                                        <Checkbox checked={isAutoClose}
                                            onChange={(e) => e.target.checked ? setIsAutoClose(true) : setIsAutoClose(false)}>Auto
                                    Closed when status Ticket is Resolved.</Checkbox>
                                    </Col>
                                </Row>
                                <Row style={{paddingLeft: '24px', marginTop: '15px'}}>
                                    {
                                        isAutoClose ? (
                                            <Row>
                                                <Col span={2} style={{paddingTop: 5}} lg={2} md={2} sm={24} xs={24}><span>After Resolved</span></Col>
                                                <Col span={2} lg={2} md={2} sm={24} xs={24}>
                                                    <Form.Item>
                                                        {getFieldDecorator('AfterResolved', {
                                                            initialValue: autoCloseDay,
                                                            rules: [
                                                                {required: true, message: 'After Resolved is required'},
                                                                {validator: validateDecimal}]
                                                        })(
                                                            <InputNumber min={1} disabled={!isAutoClose}
                                                                onChange={(value) => {
                                                                    console.log(value)
                                                                    setAutoCloseDay(value)
                                                                }}/>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1} style={{paddingTop: 5}} lg={1} md={1} sm={24}
                                                    xs={24}><span>day</span></Col>
                                            </Row>
                                        ) : null
                                    }
                                </Row>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Button type="primary"
                                        loading={isLoading}
                                        htmlType="submit"
                                        onClick={submitFrom}
                                        style={{float: 'right', marginRight: 10, marginBottom: '5px'}}>Submit</Button>
                                    <Button onClick={leavePageModal} style={{float: 'right', marginRight: 10}}>Cancel</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </TabPane>
                <TabPane tab="Ticket Template" key="2">
                    <TemplateTicketSetting />
                </TabPane>
            </Tabs>
        </>
    )
}

const MyGeneralIncidentSettingForm = Form.create<Props>()(GeneralIncidentSettingForm)

export default connect(mapStateToProps, {getAllGeneralIncidentSetting})(MyGeneralIncidentSettingForm)
