/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Icon, Input, message, Modal, Row, Switch, Table, Tooltip, Form, Button, AutoComplete } from 'antd'
import { FetchingState, fetchWithState } from '../../common-components'
import {
    Categories,
    getAllCategory,
    addCategory,
    deleteCategory,
    updateCategory,
    getAllSubCategory,
    getAllItems
} from '../../incident-management/category'

import { connect } from 'react-redux'
import { StoreState } from '../../store'
import { FormComponentProps } from 'antd/es/form'

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.categories
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    addCategory: (category: Categories) => Promise<number>;
    updateCategory: (category: Categories) => Promise<number>;
    deleteCategory: (id: number) => Promise<number>;
    getAllCategory: () => Promise<number>;
    getAllSubCategory: () => Promise<number>;
    getAllItems: () => Promise<number>;
}

interface Params {
    fetchCategory: boolean
    setFetchCategory: Function
    setFetchSubCategory: Function
    setFetchItems: Function
}

type Props = StateProps & DispatchProps & FormComponentProps & Params
const { Search } = Input
const CategoryTab: React.FC<Props> = (props: Props) => {
    const { confirm } = Modal
    const [page, setPage] = useState<number>(1)
    const pageSize: number = 10
    const [sortInfo, setSortInfo] = useState<any>({})
    const [fetchingState, setFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [activateFilter, setActivateFilter] = useState<boolean[]>([true, false])
    const [filterValue, setFilterValue] = useState<boolean[]>([true, false])
    const { getFieldDecorator, resetFields, validateFields} = props.form
    const username = sessionStorage.getItem('username') || ''
    const [dataSource, setDataSource] = useState<Categories[]>()
    const [editCat, setEditCat] = useState<Categories | undefined>(undefined)
    const [categoryLoadActive, setCategoryLoadActive] = useState<Categories | undefined>(undefined)
    const [editIsLoading, setEditIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (props.fetchCategory) {
            fetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fetchCategory])

    useEffect(() => {
        if (props.categories) {
            setDataSource(props.categories)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories])

    useEffect(() => {
        if (activateFilter.includes(true) && activateFilter.includes(false)) {
            setDataSource(props.categories)
        } else if (activateFilter.includes(true)) {
            setDataSource(props.categories?.filter(categories => categories.active === true))
        } else if (activateFilter.includes(false)) {
            setDataSource(props.categories?.filter(categories => categories.active === false))
        } else {
            setDataSource(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activateFilter])

    const fetch = () => {
        if (fetchingState === FetchingState.Succeed) {
            fetchWithState(
                FetchingState.NotStarted,
                setFetchingState,
                () => props.getAllCategory().then(() => {
                    props.setFetchCategory(false)
                }),
                (result) => {
                    // console.log(result)
                    // setCatAll(result)
                }
            )
        } else {
            fetchWithState(
                FetchingState.NotStarted,
                setFetchingState,
                () => props.getAllCategory().then(() => {
                    props.setFetchCategory(false)
                }),
                (result) => {
                    // console.log(result)
                    // setCatAll(result)
                }
            )
            // getAllCategory(getCategoryName(props.categories))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        validateFields(['catName'], (err, values) => {
            values.catName = values.catName ? values.catName.replace(/ +/g, ' ').trim() : undefined
            if (!err) {
                const NewCategory: Categories = {
                    name: values.catName,
                    active: true,
                    createdBy: username,
                    lastModifiedBy: username
                }
                // console.log(NewCategory)
                // if (!dataUpdate) {
                props.addCategory(NewCategory).then(() => {
                    message.success('You have successfully saved the data.')
                }).catch(() => {
                    message.error('The Category name, Subcategory Name and Item Name is already exists. It must be unique. Please try another one.')
                })
                resetFields()
            }
        })
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (value) {
            if (editCat) {
                const filterEditCat = props.categories?.filter((it) => it.name?.toUpperCase().trim() !== editCat.name?.toUpperCase().trim())
                if (filterEditCat) {
                    (filterEditCat || []).forEach((it) => {
                        if (it.name.toUpperCase().trim() === value.toUpperCase().trim()) {
                            callback('The Category Name already exists. It must be unique. Please try another one.')
                        }
                    })
                }
            } else {
                (props.categories || []).forEach((it) => {
                    if (it.name.toUpperCase().trim() === value.toUpperCase().trim()) {
                        callback('The Category Name already exists. It must be unique. Please try another one.')
                    }
                })
            }
            // eslint-disable-next-line
            const format = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (format.test(value)) {
                // eslint-disable-next-line
                callback('Field Label can' + 't contain any of the following characters ' + format)
            } else {
                if (pattern.test(value.trimEnd())) {
                    callback()
                } else {
                    callback('Default value is not a valid')
                }
            }
        } else {
            callback()
        }
    }

    const handleSearchValue = (value) => {
        if (value.length?.toString() === '0') {
            if (props.categories) {
                setDataSource(props.categories)
            }
        } else {
            const searchValue = props.categories.filter(catagories => catagories.name.toUpperCase().search(value.toUpperCase()) >= 0)
            setDataSource(searchValue)
        }
    }

    const toggleActiveness = (categories: Categories) => {
        try {
            const proceed = () => {
                setCategoryLoadActive(categories)
                if (categories.active) {
                    categories.active = false
                } else {
                    categories.active = true
                }
                props.updateCategory(categories).finally(() => {
                    setCategoryLoadActive(undefined)
                })
            }
            if (!categories.active) {
                proceed()
            } else {
                confirm({
                    title: 'Are you sure?',
                    content: 'Do you confirm Deactivate this data?',
                    okText: 'OK',
                    okType: 'danger',
                    autoFocusButton: 'cancel',
                    cancelText: 'Cancel',
                    async onOk() {
                        proceed()
                    },
                    onCancel() {
                    }
                })
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    const deleteCategory = (data: Categories) => {
        try {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                async onOk() {
                    if (data.active) {
                        message.warning('Please Deactivate.')
                    } else {
                        props.deleteCategory(data.id!!).then(() => {
                            props.getAllSubCategory()
                            props.getAllItems()
                        }).catch(() => {
                            message.success('Delete category successes')
                        })
                    }
                },
                onCancel() {
                }
            })
        } catch (error: any) {
            console.log(error)
        }
    }

    const handleEdit = (categories: Categories) => {
        // const cat = { ...categories }
        const tempCat: Categories = {
            active: categories.active,
            name: categories.name,
            createdBy: categories.createdBy,
            createdByDisplay: categories.createdByDisplay,
            createdById: categories.createdById,
            createdDatetime: categories.createdDatetime,
            id: categories.id,
            lastModifiedBy: categories.lastModifiedBy,
            lastModifiedByDisplay: categories.lastModifiedByDisplay,
            lastModifiedById: categories.lastModifiedById
        }
        setEditCat(tempCat)
    }

    const submitEdit = (e) => {
        e.preventDefault()
        try {
            validateFields(['editCat'], (err, values) => {
                if (!err) {
                    const editCatName = values.editCat
                    let isExists: Boolean = false
                    if (editCat) {
                        const filterEditCat = props.categories?.filter((cat) => cat.name !== editCat.name)
                        if (filterEditCat) {
                            (filterEditCat || []).forEach((it) => {
                                if (it.name.toUpperCase().trim() === editCatName.toUpperCase().trim()) {
                                    if (it.id?.toString() === editCat.id?.toString()) {
                                        setEditCat(undefined)
                                        isExists = true
                                    } else {
                                        message.error('The Category Name already exists. It must be unique. Please try another one.')
                                        console.log('invalid')
                                        isExists = true
                                    }
                                }
                            })
                        }
                        if (!isExists) {
                            setEditIsLoading(true)
                            editCat!.name = editCatName
                            props.updateCategory(editCat).then(() => {
                                message.success('The Update has finished successfully')
                                props.setFetchSubCategory(true)
                                props.setFetchItems(true)
                            }).catch((e) => {
                                message.error(e.message)
                            }).finally(() => {
                                setEditCat(undefined)
                                setEditIsLoading(false)
                                resetFields(['editCat'])
                            })
                        }
                    }
                }
            })
        } catch (error: any) {
            console.log(error)
        }
    }

    const handleFilter = (value) => {
        setFilterValue(value)
        console.log(value)
        if (value) {
            if (value.length === 1) {
                if (value[0] === 'true' || value[0] === true) {
                    setActivateFilter([true])
                } else {
                    setActivateFilter([false])
                }
            } else if (value.length === 2) {
                setActivateFilter([true, false])
            } else {
                setActivateFilter([])
            }
            setPage(1)
        }
    }

    const renderFilter = () => {
        return (<>
            <Checkbox.Group style={{ width: '100%' }} onChange={handleFilter} value={filterValue}>
                <Row>
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={true}>Activate</Checkbox>
                    </Col>
                    <br />
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={false}>Deactivate</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </>)
    }

    const getPage = (page: number) => {
        setPage(page)
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        getPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        setSortInfo(sorter)
    }

    const sorter = (a: string, b: string) => (a !== null && b !== null ? (a || '').localeCompare(b || '') : (b || '').localeCompare(a || ''))

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row: any) =>
                <span><Icon type="delete" onClick={() => deleteCategory(row)} /></span>
        }, {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row: any) => {
                if (editCat?.id?.toString() === row.id?.toString()) {
                    return <Form.Item><Button icon='save' style={{ border: 0, background: 'rgb(0,0,0,0)' }} htmlType="submit"></Button> </Form.Item>
                }
                return (
                    <span><Icon type="edit" onClick={() => handleEdit(row)} /> </span>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: '',
            key: 'name',
            sorter: (a, b) => sorter(a.name, b.name),
            sortOrder: sortInfo.columnKey === 'name' && sortInfo.order,
            ellipsis: true,
            render: (row: Categories) => {
                if (editCat?.id?.toString() === row.id?.toString()) {
                    return (
                        <Form.Item>
                            {getFieldDecorator('editCat',
                                {
                                    initialValue: row.name,
                                    rules: [{ required: true, message: 'Category Name is required' },
                                        { max: 250, message: 'message max 250 characters' },
                                        { validator: validateNameUniqueness }
                                    ]
                                })(
                                <AutoComplete
                                    placeholder="Category Name"
                                    disabled={editIsLoading}
                                    allowClear
                                    // onSelect={(e) => handelSelectCat(e)}
                                    filterOption={(inputValue, option) =>
                                        (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                            )}
                        </Form.Item>
                    )
                }
                return (
                    <>{row.name}</>
                )
            }
        },
        {
            title: 'Last Modified',
            dataIndex: '',
            key: '',
            width: '20%',
            render: (row: Categories) => {
                return (
                    <>
                        {row.lastModifiedDatetime?.length === 23 ? row.lastModifiedDatetime.substring(0, row.lastModifiedDatetime.length - 4) : row.lastModifiedDatetime}
                    </>
                )
            }
        },
        {
            title: '',
            dataIndex: 'active',
            key: 'active',
            width: '10%',
            filters: [
                {
                    text: 'Activate',
                    value: 'true'
                },
                {
                    text: 'Deactivate',
                    value: 'false'
                }
            ],
            filteredValue: filterValue,
            filterDropdown: renderFilter,
            render: (check, row) => {
                return (
                    <>
                        <Tooltip placement="bottom" title={row.active ? 'Deactivate' : 'Activate'}>
                            <Switch style={{ float: 'right' }} loading={categoryLoadActive?.id?.toString() === row.id?.toString()} checked={check}
                                onChange={() => toggleActiveness(row)} />
                        </Tooltip>
                    </>
                )
            }
        }
    ]

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row gutter={[10, 10]}>
                    <Col md={18} sm={24}>
                        <Form.Item label={'Category Name'}>
                            {getFieldDecorator('catName',
                                {
                                    initialValue: undefined,
                                    rules: [{ required: true, message: 'Category Name is required' },
                                        { max: 250, message: 'message max 250 characters' },
                                        { validator: validateNameUniqueness }]
                                })(
                                <AutoComplete
                                    // dataSource={[...new Set(allCatName)]}
                                    placeholder="Category Name"
                                    allowClear
                                    // onSelect={(e) => handelSelectCat(e)}
                                    filterOption={(inputValue, option) =>
                                        (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset"
                                onClick={() => resetFields()}>Cancel</Button>
                        </Form.Item>
                    </Col>
                    <Col span={3} lg={3} md={3} sm={24} xs={24}>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                                htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div style={{ overflow: window.innerWidth <= 768 ? 'auto' : 'none' }}>
                {/* <TableCategory getAllCategory={getAllCategory} handleOnClickInOtherPage={handleOnClickInOtherPage} getDataToEdit={getDataToEdit} getPage={getPage} /> */}
                <div>
                    <div style={{ float: 'right' }}>
                        <Row gutter={16}>
                            <Col span={7}>
                                <Search
                                    // defaultValue={value}
                                    placeholder="Can search Category"
                                    onSearch={value => handleSearchValue(value)}
                                    style={{ width: 300 }}
                                    id='search_category'
                                    allowClear
                                />
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <br />
                    <Form onSubmit={submitEdit}>
                        <Table
                            columns={columns}
                            dataSource={dataSource || []}
                            // expandedRowRender={expandedRowRender}
                            // defaultExpandAllRows={true}
                            pagination={{
                                pageSize: pageSize,
                                current: page,
                                total: dataSource?.length || 0,
                                onChange: (event) => {
                                    handleOnClickPageNumber(event)
                                }
                            }}
                            rowKey="id"
                            onChange={handleChangeTable}
                            // loading={fetchingState !== 2}
                            locale={{
                                filterConfirm: '',
                                filterReset: ''
                            }}
                            expandIconColumnIndex={0}
                            expandIconAsCell={false}
                            scroll={{ x: 768 }}
                        />
                    </Form>
                </div>
            </div>
        </div>
    )
}

const CategoryTabFrom = Form.create<Props>()(CategoryTab)
export default connect(mapStateToProps,
    { addCategory, updateCategory, getAllCategory, deleteCategory, getAllSubCategory, getAllItems }
)(CategoryTabFrom)
