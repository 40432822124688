/* eslint-disable no-unused-expressions */
/* eslint-disable no-new-wrappers */
import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Avatar, Tooltip, Tag, Table, Modal, Select, Icon, Empty } from 'antd'
import DoughnutChart from './Chart/DoughnutChart'
import VerticleBarChart from './Chart/VerticleBarChart'
import ImgGoldMedal from '../assets/images/GoldMedal.png'
import ImgSilverMedal from '../assets/images/SilverMedal.png'
import ImgBronzeMedal from '../assets/images/BronzeMedal.png'
import ImgTrophy from '../assets/images/Trophy.png'
import ImgSilverTopLike from '../assets/images/Silver.png'
import ImgBronzeTopLike from '../assets/images/Bronze.png'
import { StoreState } from '../store'
import { connect } from 'react-redux'
import { DataForChart, imageTopcreate, Km, TopCreateKMDashboard, TopLikeKMDashboard, TopViewKMDashboard } from './model'
import { ConvertTopLikeKnowledgeToChart, ConvertTopViewKnowledgeToChart, ConvertTotalKnowledgeToChart, getKnowledgeDashboardWithFilter } from './service'
import ReactHtmlParser from 'react-html-parser'
import AutoAvatar from '../common-components/autoAvatar'

const mapStateToProps = (state: StoreState) => {
    return {
        AllKmDashboard: state.AllKmDashboard
    }
}

interface Param {
    category?: string
    groupOffKm: {
        show: number | string,
        created: string,
        time?: string[]
    }
}

const { Option } = Select
const pageSize = 10
type StateProps = ReturnType<typeof mapStateToProps>

type Props = StateProps & Param
const KnowledgeLayoutChart: React.FC<Props> = (props: Props) => {
    const [totalKnowledge, setTotalKnowledge] = useState<DataForChart>()
    const [toplikeKnowledge, setToplikeKnowledge] = useState<DataForChart>()
    const [toplikeKnowledgeTable, setToplikeKnowledgeTable] = useState<TopLikeKMDashboard>()
    const [topViewKnowledge, setTopViewKnowledge] = useState<DataForChart>()
    const [topViewKnowledgeTable, setTopViewKnowledgeTable] = useState<TopViewKMDashboard>()
    const [topCreateKnowledgeTable, setTopCreateKnowledgeTable] = useState<TopCreateKMDashboard[]>()
    const [isLoadingToplikeKnowledgeTable, setIsLoadingToplikeKnowledgeTable] = useState<boolean>(false)
    const [selectTopLike, setSelectToplike] = useState<number>()
    const TopCreateMedalImg = [ImgGoldMedal, ImgSilverMedal, ImgBronzeMedal]
    const ToplikeMedalImg = [ImgTrophy, ImgSilverTopLike, ImgBronzeTopLike]
    const [isModalVidsible, setIsModalVisible] = useState<boolean>(false)
    const [toplikeKnowledgeTablechoose, setToplikeKnowledgeTablechoose] = useState<Km[] | undefined>()
    const [topViewKnowledgeTablechoose, setTopViewKnowledgeTablechoose] = useState<Km[] | undefined>()
    const [totalLikeKm, setTotalLikeKm] = useState<number>()
    const [loadingdata, setloadingdata] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const [totalTopLikeKmTable, setTotalTopLikeKmTable] = useState<number>()

    useEffect(() => {
        const show = props.groupOffKm.show
        const created = props.groupOffKm.created
        const time0 = props.groupOffKm.time ? props.groupOffKm.time[0] : undefined
        const time1 = props.groupOffKm.time ? props.groupOffKm.time[1] : undefined
        setIsLoadingToplikeKnowledgeTable(true)
        if (selectTopLike) {
            getKnowledgeDashboardWithFilter(show !== 'all' ? show : undefined, created, time0, time1, selectTopLike, page, pageSize).then((response) => {
                setTotalLikeKm(response.topLikeKm.topLikeKm[0].amountLike)
                setToplikeKnowledgeTablechoose(response.topLikeKm.topLikeKm[0].knowledges)
                setTopViewKnowledgeTablechoose(response.topLikeKm.topViewKm[0].knowledges)
                setTotalTopLikeKmTable(response.topLikeKm.totalKmTopLike)
            }).catch(err => {
                console.log(err)
                setToplikeKnowledgeTablechoose(undefined)
                setTopViewKnowledgeTablechoose(undefined)
                setTotalLikeKm(0)
            }).finally(() => {
                setIsLoadingToplikeKnowledgeTable(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTopLike, page, props.groupOffKm])

    useEffect(() => {
        setToplikeKnowledge(ConvertTopLikeKnowledgeToChart(props.AllKmDashboard.topLikeKm))
        setTopViewKnowledge(ConvertTopViewKnowledgeToChart(props.AllKmDashboard.topViewKm))
        setTotalKnowledge(ConvertTotalKnowledgeToChart(props.AllKmDashboard))
        setTopCreateKnowledgeTable(props.AllKmDashboard.topCreateKm)
        sortToplikeKnowLedgeTable()
        sortTopViewKnowLedgeTable()
        setloadingdata(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.AllKmDashboard])

    useEffect(() => {
        setPage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groupOffKm, selectTopLike])

    const sortToplikeKnowLedgeTable = async () => {
        await props.AllKmDashboard.topLikeKm?.topLikeKm.sort(function (a, b) {
            const keyA = new Number(a.ordinalNumber)
            const keyB = new Number(b.ordinalNumber)
            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            return 0
        })
        setToplikeKnowledgeTable(props.AllKmDashboard.topLikeKm)
        setloadingdata(false)
    }

    const sortTopViewKnowLedgeTable = async () => {
        await props.AllKmDashboard.topViewKm?.topViewKm.sort(function (a, b) {
            const keyA = new Number(a.ordinalNumber)
            const keyB = new Number(b.ordinalNumber)
            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            return 0
        })
        setTopViewKnowledgeTable(props.AllKmDashboard.topViewKm)
        setloadingdata(false)
    }

    const columns = [
        {
            dataIndex: 'img',
            key: 'img',
            width: '10%',
            render: (item, record, index) => <img key={index} width={'25px'} src={TopCreateMedalImg[index]} alt={'Medal Image' + index} />
        },
        {
            dataIndex: 'ordinalNumber',
            key: 'ordinalNumber',
            width: '10%',
            render: (item) => <div style={{ marginTop: '7%' }}>{item}</div>
        },
        {
            dataIndex: 'authors',
            key: 'authors',
            width: '45%',
            render: (item, record, index) => {
                const tempName: string[] = []
                const tempImg: imageTopcreate[] = []
                if (record.authors.length > 3) {
                    for (let i = 3; i < record.authors.length; i++) {
                        tempName.push('• ' + record.authors[i].fullName)
                    }
                }
                for (const it of record.authors) {
                    if (it.pictureProfile !== '') {
                        const convertData = JSON.parse(it.pictureProfile)
                        if (convertData) {
                            convertData?.map((file) => {
                                const uploadFile: imageTopcreate = {
                                    name: file.name,
                                    status: 'done',
                                    url: file.url,
                                    type: file.type
                                }
                                tempImg.push(uploadFile)
                            })
                        } else {
                            tempImg.push({ url: undefined })
                        }
                    }
                }
                return <div className='AvatarGroup'>
                    {record.authors.length < 2 ? record.authors.map((it, index) => {
                        return <Tooltip key={index} title={'• ' + it.fullName} placement='bottom'>
                            {tempImg[index]?.url ? <Avatar style={{ marginLeft: '-10px', marginRight: '10px', width: '45px', height: '45px' }} alt={tempImg[index] ? tempImg[index].name : ''} src={tempImg[index] ? tempImg[index].url : ''} />
                                : <AutoAvatar isKmDashboardStyle={true} isShowTooltips={false} isMultiUser={false} title={it.fullName} broken={false} username={it.fullName} />}  {it.fullName}
                        </Tooltip>
                    }) : record.authors?.map((it, index) => {
                        return index < 3
                            ? tempImg[index]?.url ? <Tooltip key={index} title={'• ' + it.fullName} placement='bottom'>
                                <Avatar className='Avatars' alt={tempImg[index] ? tempImg[index].name : ''} src={tempImg[index] ? tempImg[index].url : ''} ></Avatar>
                            </Tooltip> : <AutoAvatar key={index} isShowTooltips={true} isMultiUser={true} title={it.fullName} broken={false} username={it.fullName} /> : undefined
                    })}
                    {record.authors.length > 3
                        ? <Tooltip placement='bottom' title={ReactHtmlParser(tempName.join('</br>'))}><Avatar className='Avatars' style={{ backgroundColor: 'rgb(235, 87, 87)', color: 'white' }} > +{record.authors.length - 3} </Avatar> </Tooltip> : undefined}
                </div>
            }
        },
        {
            key: 'amountCreated',
            dataIndex: 'amountCreated',
            width: '35%',
            render: (item) => <div style={{ marginTop: '7%' }}> <p className='sub-title'> {item} Knowledge/User </p> </div>
        }
    ]

    const columnsmodal = [
        {
            dataIndex: 'ticketNumber',
            key: 'ticketNumber',
            width: '40%',
            render: (item, record) => {
                return <div style={{ marginTop: '1%' }}> <span style={{ color: '#F6712E', fontSize: '14px', marginRight: '13px' }}> •</span> {record.ticketNumber}</div>
            }
        },
        {
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (item, record) => {
                return <div style={{ marginTop: '1%' }}> <Icon style={{ marginRight: '13px' }} type="contacts" /> {record.createdBy} </div>
            }
        }
    ]

    const handleClickTag = (value) => {
        setIsModalVisible(true)
        setSelectToplike(value)
    }

    return (
        <div>
            <Row className="TopicDashboard">
                <Col span={24}>
                    <span className="main-title">Total knowledge</span>
                </Col>
            </Row>
            <br />
            <Row style={{ height: 'auto' }} gutter={[16, 16]}>
                <Col span={10} xs={24} sm={24} md={10} lg={10} style={{ marginBottom: '2%' }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card className="Box-DashBoard" >
                                <p className="sub-title">Knowledge ({props.AllKmDashboard.totalPublishKm ? props.AllKmDashboard.totalPublishKm : 0} Knowledge)</p>
                                {props.AllKmDashboard?.totalPublishKm !== 0
                                    ? <Row style={{ minHeight: 90, marginTop: '5%' }} gutter={8}>
                                        <Col span={16} style={{ alignContent: 'center', justifyContent: 'center' }}>
                                            <DoughnutChart textInsideDoughnut={true} cutout={70} Height={180} showLabels={false} marginTop={'2%'} myData={totalKnowledge} fixPosition={'right'} />
                                        </Col>
                                        {props.AllKmDashboard?.totalPublishKm !== 0 ? <Col style={{ marginTop: '6rem' }} span={8}>
                                            <Tooltip title={totalKnowledge?.labels}>
                                                <span style={{ backgroundColor: '#2F80ED', marginRight: '5%', maxHeight: 5}}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                {totalKnowledge?.labels}
                                            </Tooltip>
                                        </Col> : null}
                                    </Row> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card className="Box-DashBoard" >
                                <p className="sub-title">Top Create Knowledge</p>
                                <Row style={{ minHeight: 90, marginTop: '5%' }}>
                                    <Table rowKey='ordinalNumber' columns={columns} dataSource={topCreateKnowledgeTable} pagination={false} showHeader={false} />
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={14} xs={24} sm={24} md={14} lg={14} style={{ marginBottom: '2%' }}>
                    <Card className="Box-DashBoard" loading={loadingdata} >
                        <Row>
                            <p className="sub-title">Top like knowledge ({toplikeKnowledgeTable?.totalKmTopLike ? toplikeKnowledgeTable.totalKmTopLike : 0} Knowledge / {props.AllKmDashboard.totalPublishKm ? props.AllKmDashboard.totalPublishKm : 0} Knowledge)</p>
                        </Row>
                        <br /> <br />
                        {toplikeKnowledge?.labels.length !== 0
                            ? <Row>
                                <Col span={12}>
                                    {toplikeKnowledgeTable?.topLikeKm.map((it, idx) => {
                                        return <div style={{ height: '180px' }} key={idx}>
                                            <Row gutter={8}>
                                                <Col span={4}>
                                                    <div style={{ display: 'flex', alignContent: 'end', justifyContent: 'center' }}>
                                                        <img width={'30px'} src={ToplikeMedalImg[idx]} alt={'Trophy Image' + idx} />
                                                    </div>
                                                </Col>
                                                <Col span={20}>
                                                    <span className="sub-title">{it.ordinalNumber} {it.ordinalNumber === 1 || it.ordinalNumber === 2 ? it.ordinalNumber === 1 ? 'st' : 'nd' : 'rd'} ({it.knowledges.length} Knowledge)</span>
                                                    <Tooltip title={<span>
                                                        {it.knowledges.map((it02, idx) => {
                                                            return idx < 11 ? <p key={idx}>• {it02.ticketNumber} </p> : null
                                                        })}
                                                    </span>} placement='bottom' >
                                                        <br /> <br />
                                                        <Tag key={idx} onClick={e => handleClickTag(it.ordinalNumber)} color={it.ordinalNumber === 1 || it.ordinalNumber === 2 ? it.ordinalNumber === 1 ? '#69BB57' : '#FAAD14' : '#F6712E'} > {it.knowledges.length} Knowledge</Tag>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </div>
                                    })}
                                </Col>
                                <Col span={12}>
                                    <VerticleBarChart myData={toplikeKnowledge} />
                                </Col>
                            </Row> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </Card>
                </Col>
                <Col span={24} xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '2%' }}>
                    <Card className="Box-DashBoard" loading={loadingdata} >
                        <Row>
                            <p className="sub-title">Top View knowledge ({topViewKnowledgeTable?.totalKmTopView ? topViewKnowledgeTable.totalKmTopView : 0} Knowledge / {props.AllKmDashboard.totalPublishKm ? props.AllKmDashboard.totalPublishKm : 0} Knowledge)</p>
                        </Row>
                        <br /> <br />
                        {topViewKnowledge?.labels.length !== 0
                            ? <Row>
                                <Col span={12}>
                                    {topViewKnowledgeTable?.topViewKm.map((it, idx) => {
                                        return <div style={{ height: '180px' }} key={idx}>
                                            <Row gutter={8}>
                                                <Col span={4}>
                                                    <div style={{ display: 'flex', alignContent: 'end', justifyContent: 'center' }}>
                                                        <img width={'30px'} src={ToplikeMedalImg[idx]} alt={'Trophy Image' + idx} />
                                                    </div>
                                                </Col>
                                                <Col span={20}>
                                                    <span className="sub-title">{it.ordinalNumber} {it.ordinalNumber === 1 || it.ordinalNumber === 2 ? it.ordinalNumber === 1 ? 'st' : 'nd' : 'rd'} ({it.knowledges.length} Knowledge)</span>
                                                    <Tooltip title={<span>
                                                        {it.knowledges.map((it02, idx) => {
                                                            return idx < 11 ? <p key={idx}>• {it02.ticketNumber} </p> : null
                                                        })}
                                                    </span>} placement='bottom' >
                                                        <br /> <br />
                                                        <Tag key={idx} onClick={e => handleClickTag(it.ordinalNumber)} color={it.ordinalNumber === 1 || it.ordinalNumber === 2 ? it.ordinalNumber === 1 ? '#69BB57' : '#FAAD14' : '#F6712E'} > {it.knowledges.length} Knowledge</Tag>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </div>
                                    })}
                                </Col>
                                <Col span={12}>
                                    <VerticleBarChart myData={topViewKnowledge} />
                                </Col>
                            </Row> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </Card>
                </Col>
            </Row>
            <Modal
                title="Top Like Knowledge"
                visible={isModalVidsible}
                onCancel={(e) => setIsModalVisible(false)}
                width={420}
                footer={null}

            >
                <Row>
                    <Col span={18} />
                    <Col span={6}>
                        <p>Show Top like :</p>
                        <Select style={{ width: 100 }} value={selectTopLike} onChange={(value: number) => { setSelectToplike(value) }}>
                            <Option value={1}>1 st</Option>
                            <Option value={2}>2 nd</Option>
                            <Option value={3}>3 rd</Option>
                        </Select>
                    </Col>
                </Row>
                <br />
                <Row className="TopicDashboard">
                    <Col span={24}>
                        <span className="main-title"><Icon type="like" theme="filled" /> {totalLikeKm || 0} Like</span>
                    </Col>
                </Row>
                {toplikeKnowledgeTablechoose ? <Table loading={isLoadingToplikeKnowledgeTable} rowKey="ticketNumber"
                    showHeader={false} columns={columnsmodal}
                    dataSource={toplikeKnowledgeTablechoose}
                    pagination={{
                        pageSize: pageSize,
                        current: page,
                        total: totalTopLikeKmTable,
                        onChange: (event) => {
                            setPage(event)
                        }
                    }} />
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                {topViewKnowledgeTablechoose ? <Table loading={isLoadingToplikeKnowledgeTable} rowKey="ticketNumber"
                    showHeader={false} columns={columnsmodal}
                    dataSource={topViewKnowledgeTablechoose}
                    pagination={{
                        pageSize: pageSize,
                        current: page,
                        total: totalTopLikeKmTable,
                        onChange: (event) => {
                            setPage(event)
                        }
                    }} />
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

            </Modal>
        </div>

    )
}

export default connect(mapStateToProps, {

})(KnowledgeLayoutChart)
