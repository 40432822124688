export interface Dashboard {
    data: any
    total: number
}

export interface DataTotal {
    data: any
    total: number
}

export interface DataForChart {
    labels: Array<string>
    datasets: Array<DataSet>
}

export interface DataSet {
    data: Array<number>
    backgroundColor: Array<string>
    hoverBackgroundColor: Array<string>
    borderColor?: Array<string>
    borderWidth?: number
}

export interface IncidentBacklog {
    _id: string
    slaGoalName?: string
    measurementClockStartTime?: string
    slaTargetTime?: string
    slaTag?: string
    slaPolicyName?: string
    number: string
    subject: string
    priority: string
    status: string
    supportTeam: string
    assignee: string
    createdDatetime: string
    ticketId: string
}

export enum ChartType {
    OpenTicket = 'open-ticket',
    OpenByStatus = 'status',
    OpenByPriority = 'priority',
    OpenSLA = 'sla',
    IncidentBacklog = 'incidentBacklog',
    Responded = 'responded',
    Remainder = 'remaining',
    TopLikeKm = 'topLikeKm',
    TopCreateKm = 'topCreateKm',
    TotalPublishKm = 'totalPublishKm'
}

export enum GroupType {
    TotalGroup = 'TotalGroup',
    MyGroup = 'MyGroup',
    MyTicket = 'MyTicket',
}

export const Color = [
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163',
    '#EF701F', '#EFD658', '#1CBB9F', '#0890C2', '#606163'
]

// Complete  4DC977
// Due in 2F80ED
// Due soon ED9E28
// Missed EB5757
// Overdue #726E6D

export const ColorSLA = ['#4DC977', '#2F80ED', '#ED9E28', '#EB5757', '#726E6D']

export interface KnowledgeDashboard {
    totalPublishKm: number,
    topCreateKm: TopCreateKMDashboard[]
    topLikeKm: TopLikeKMDashboard
    topViewKm: TopViewKMDashboard
}

export interface TopCreateKMDashboard {
    ordinalNumber: number,
    amountCreated: number,
    authors: author[]

}

export interface TopLikeKMDashboard {
    topLikeKm: topLikeKmData[],
    totalKmTopLike: number
}

export interface Km {
    ticketNumber: string,
    createdBy: string,
}

export interface author {
    id: number,
    firstName: string,
    lastName: string,
    fullName: string,
    email: string,
    pictureProfile?: string
}

export interface topLikeKmData {
    ordinalNumber: number,
    amountLike: number,
    knowledges: Km[]
}

export interface imageTopcreate {
    name?: string,
    status?: string,
    url?: string,
    type?: string,
    size?: number
}

export interface TopViewKMDashboard {
    topViewKm: topViewKmData[],
    totalKmTopView: number
}

export interface topViewKmData {
    ordinalNumber: number,
    amountView: number,
    knowledges: Km[]
}

export const ColortotalPubKm = ['#2F80ED']

export const ColorToplikeKm = ['#FAAD14', '#69BB57', '#F6712E']
