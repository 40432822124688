/* eslint-disable no-useless-escape */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Empty, Form, Input, message, Modal, Row, Select, Spin, Tag } from 'antd'
import { connect } from 'react-redux'
import { StoreState } from '../../store'
import { FormComponentProps } from 'antd/lib/form'
import { SelectValue } from 'antd/es/select'
import { CompactPicker } from 'react-color'
import { PriorityMatrix, PriorityLevel } from './model'
import { createPriorityMatrixNewVersion, getAllPriority, getPriorityConfiguration, createNewPriorityLevel } from './service'
import { Link, Redirect } from 'react-router-dom'
import { getAllUrgency } from '../urgency'
import { getAllImpact } from '../impact'
import { getCountIncident } from '../incident/service'
// import { getAllIncident } from '../incident/service'

const mapStateToProps = (state: StoreState) => {
    return {
        priorityMatrix: state.priorityMatrix,
        incidents: state.incidentState.allIncident || [],
        priority: state.priorities,
        impact: state.impacts,
        urgency: state.urgencies
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    createPriorityMatrixNewVersion: (priorityConfiguration: PriorityMatrix[] | undefined) => Promise<number>
    getPriorityConfiguration: () => Promise<number>
    // getAllIncident: () => Promise<number>
    getAllPriority: () => Promise<number>
    getAllImpact: () => Promise<number>
    getAllUrgency: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

let tempPriorityMatrix: PriorityMatrix[] = []
let priorityMatrixObj: any[] = []
let originMatrix: any[] = []
let tempPriorityVersion: number | undefined = 0
const { Option } = Select
const PriorityConfigurationForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [tagColor, setTagColor] = useState('#4D4D4D')
    const [showTag, setShowTag] = useState(true)
    const [listOfTagPriority, setListOfTagPriority] = useState<any[]>([])
    const [listOfImpact, setListOfImpacted] = useState<any[]>([])
    const [listOfUrgency, setListOfUrgencies] = useState<any[]>([])
    const [isRequirePriority, setIsRequirePriority] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedPriorityTag, setSelectedPriorityTag] = useState('')
    const [isRedirect, setIsRedirect] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [countIncident, setCountIncident] = useState(0)
    const [isLoadingCountIncident, setIsLoadingCountIncident] = useState(false)

    useEffect(() => {
        setIsLoadingCountIncident(true)
        getCountIncident().then((res) => {
            setCountIncident(res)
        }).finally(() => {
            setIsLoadingCountIncident(false)
        })
        const promiseAll: any[] = []
        promiseAll.push(fetchPriorityConfiguration())
        promiseAll.push(fetchDataPriority())
        promiseAll.push(fetchDataImpact())
        promiseAll.push(fetchDataUrgency())
        Promise.all(promiseAll).catch((err) => {
            message.error(`Failed fetching all Data. ${err}`)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.priorityMatrix && props.priority.length !== 0 &&
            props.impact.length !== 0 && props.urgency.length !== 0) {
            initialPriorityData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.priority, props.impact, props.urgency, props.priorityMatrix])

    const fetchDataPriority = () => {
        return props.getAllPriority()
    }

    const fetchDataImpact = () => {
        return props.getAllImpact()
    }

    const fetchDataUrgency = () => {
        return props.getAllUrgency()
    }

    const fetchPriorityConfiguration = () => {
        return props.getPriorityConfiguration()
    }

    const initialPriorityData = () => {
        tempPriorityMatrix = []
        priorityMatrixObj = []
        originMatrix = []
        const listOfImpactName = props.impact.map((item) => item.name)
        const listOfUrgencyName = props.urgency.map((item) => item.name)

        props.form.setFieldsValue({
            Impact: listOfImpactName,
            Urgency: listOfUrgencyName
        })
        setListOfImpacted(props.impact)
        setListOfUrgencies(props.urgency)
        setListOfTagPriority(props.priority)
        tempPriorityMatrix = props.priorityMatrix
        tempPriorityVersion = props.priorityMatrix.map(it => it.priorityVersion)[0]
        props.priorityMatrix.forEach((item) => {
            if (item) {
                priorityMatrixObj.push({
                    impact: {
                        id: item.impact?.id,
                        name: item.impact?.name
                    },
                    urgency: {
                        id: item.urgency?.id,
                        name: item.urgency?.name
                    },
                    priority: {
                        id: item.priority?.id,
                        name: item.priority?.name
                    },
                    priorityVersion: item.priorityVersion
                })
                originMatrix.push({
                    impact: {
                        id: item.impact?.id,
                        name: item.impact?.name
                    },
                    urgency: {
                        id: item.urgency?.id,
                        name: item.urgency?.name
                    },
                    priority: {
                        id: item.priority?.id,
                        name: item.priority?.name
                    },
                    priorityVersion: item.priorityVersion
                })
            }
        })
    }
    const modifyPriorityMatrixToDisplay = (impact, urgency, priority) => {
        if (!priority) {
            const obj: PriorityMatrix = {
                impact: {
                    name: impact,
                    number: 0,
                    tagColor: '',
                    type: 'impact'
                },
                urgency: {
                    name: urgency,
                    number: 0,
                    tagColor: '',
                    type: 'urgency'
                },
                priority: {
                    name: '',
                    number: 0,
                    tagColor: '',
                    type: 'priority'
                },
                priorityVersion: tempPriorityVersion
            }
            tempPriorityMatrix.push(obj)
            priorityMatrixObj.push(obj)
        }
    }

    const generateMatrixToCreate = (impact, urgency, priority) => {
        const isPriorityId = typeof priority === 'number'
        const index = priorityMatrixObj.findIndex(origin => (origin.impact?.name === impact.name && origin.urgency?.name === urgency.name))
        priorityMatrixObj[index] = {
            impact: {
                id: 0,
                name: ''
            },
            urgency: {
                id: 0,
                name: ''
            },
            priority: {
                id: 0,
                name: ''
            },
            priorityVersion: priorityMatrixObj[index].priorityVersion
        }
        if (impact.id) { priorityMatrixObj[index].impact.id = impact.id } else { priorityMatrixObj[index].impact.name = impact.name }
        if (urgency.id) { priorityMatrixObj[index].urgency.id = urgency.id } else { priorityMatrixObj[index].urgency.name = urgency.name }
        if (isPriorityId) { priorityMatrixObj[index].priority.id = priority } else { priorityMatrixObj[index].priority.name = priority }
        if (impact.name) priorityMatrixObj[index].impact.name = impact.name
        if (urgency.name) priorityMatrixObj[index].urgency.name = urgency.name
    }

    const onImpactChanged = (value: SelectValue) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false

        if (hasLowerCaseAndDuplicates(value)) {
            selectedValue.pop()
        }

        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return
        }
        selectedValue.forEach((name: string, index: number) => {
            if (pattern.test(name.replace(/ +/g, ' ').trim())) {
                const obj: PriorityLevel = {
                    tagColor: '',
                    name: name.replace(/ +/g, ' ').trim(),
                    number: index,
                    type: 'impact'
                }
                setListOfImpacted([...listOfImpact, obj])
            }
        })
        /* if origin impact < new impact [add impact] */
        if (listOfImpact.length < selectedValue.length) {
            listOfUrgency.forEach((urgency) => {
                modifyPriorityMatrixToDisplay(value[selectedValue.length - 1].replace(/ +/g, ' ').trim(), urgency.name.replace(/ +/g, ' ').trim(), '')
            })
        } else {
            /* [delete impact] */
            modifyImpactValueBeforeGenerateMatrix(value)
        }
    }

    const modifyImpactValueBeforeGenerateMatrix = (selectedValue) => {
        listOfImpact.forEach((it, index) => {
            const isNotDeleted = selectedValue.includes(it.name)
            if (isNotDeleted === false) {
                for (let i = tempPriorityMatrix.length - 1; i >= 0; --i) {
                    if (tempPriorityMatrix[i].impact!!.name === it.name) {
                        tempPriorityMatrix.splice(i, 1)
                    }
                }
                for (let i = priorityMatrixObj.length - 1; i >= 0; --i) {
                    if (priorityMatrixObj[i].impact!!.name === it.name) {
                        priorityMatrixObj.splice(i, 1)
                    }
                }
                listOfImpact.splice(index, 1)
                const tempListOfImpact = listOfImpact
                setListOfImpacted(tempListOfImpact)
            }
        })
    }

    const onUrgencyChanged = (value: SelectValue) => {
        const selectedValue = (value as Array<string>)
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false

        if (hasLowerCaseAndDuplicates(value)) {
            selectedValue.pop()
        }

        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return
        }
        selectedValue.forEach((name: string, index: number) => {
            if (pattern.test(name)) {
                const obj: PriorityLevel = {
                    tagColor: '',
                    name: name.replace(/ +/g, ' ').trim(),
                    number: index,
                    type: 'urgency'
                }
                setListOfUrgencies([...listOfUrgency, obj])
            }
        })
        if (listOfUrgency.length < selectedValue.length) {
            listOfImpact.forEach((impact) => {
                modifyPriorityMatrixToDisplay(impact.name.replace(/ +/g, ' ').trim(), value[selectedValue.length - 1].replace(/ +/g, ' ').trim(), '')
            })
        } else {
            modifyUrgencyValueBeforeGenerateMatrix(value)
        }
    }

    const modifyUrgencyValueBeforeGenerateMatrix = (urgency) => {
        listOfUrgency.forEach((it) => {
            if (listOfUrgency.length > urgency.length) {
                const isDeleted = urgency.includes(it.name)
                if (isDeleted === false) {
                    listOfImpact.forEach(() => {
                        for (let i = tempPriorityMatrix.length - 1; i >= 0; --i) {
                            if (tempPriorityMatrix[i].urgency!!.name === it.name) {
                                tempPriorityMatrix.splice(i, 1)
                            }
                        }
                        for (let i = priorityMatrixObj.length - 1; i >= 0; --i) {
                            if (priorityMatrixObj[i].urgency!!.name === it.name) {
                                priorityMatrixObj.splice(i, 1)
                            }
                        }
                    })
                    const filterData = listOfUrgency.filter((data) => it.id === undefined ? data.name !== it.name : data.id !== it.id)
                    setListOfUrgencies(filterData)
                    const tempListOfImpact = listOfImpact
                    setListOfImpacted(tempListOfImpact)
                }
            }
        })
    }

    const handleAddPriority = () => {
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const newPriority = props.form.getFieldValue('priority')
        if (format.test(newPriority)) {
            return
        }
        const existPriority = listOfTagPriority.map(it => it.name)
        props.form.setFieldsValue({
            priority: undefined
        })
        if (newPriority) {
            if (!existPriority.includes(newPriority.trim())) {
                setIsRequirePriority(false)
                setShowTag(true)
                const priority: PriorityLevel = {
                    name: newPriority.replace(/ +/g, ' ').trim(),
                    tagColor: tagColor,
                    number: listOfTagPriority.length + 1,
                    type: 'priority'
                }
                setListOfTagPriority([...listOfTagPriority, priority])
            }
        } else {
            setIsRequirePriority(true)
        }
    }

    const handleCloseTag = (removedTag: any) => {
        const tags = listOfTagPriority.filter(tag => tag !== removedTag)
        setIsRequirePriority(false)
        tags.forEach((tags, i) => tags.order = i + 1)
        /* remove value from field that have priority value that deleted */
        tempPriorityMatrix.forEach((matrix) => {
            const json = {}
            if (matrix.priority?.name === removedTag.name) {
                const key = `${matrix.urgency?.name}priority${matrix.impact?.name}`
                json[key] = undefined
                props.form.setFieldsValue(json)
                matrix.priority = {
                    name: '',
                    tagColor: '',
                    number: 0,
                    type: 'priority'
                }
            }
        })
        setListOfTagPriority(tags)
    }

    const renderPriorityTag = () => {
        return listOfTagPriority.map((item) => {
            return (
                <Tag
                    closable={countIncident === 0}
                    key={item.name}
                    color={item.tagColor}
                    style={{ margin: '5px' }}
                    onClick={() => onClickTag(item)}
                    onClose={() => handleCloseTag(item)}
                >
                    {item.name}
                </Tag>
            )
        })
    }

    const onClickTag = (priorityTag) => {
        setShowModal(true)
        setSelectedPriorityTag(priorityTag.name)
        setTagColor(priorityTag.tagColor)
    }
    const handleSubmitTable = () => {
        setIsRequirePriority(false)
        props.form.validateFields((err: any) => {
            if (!err) {
                const promiseAll: any[] = []
                setIsLoading(true)
                promiseAll.push(createNewPriorityLevel(listOfImpact))
                promiseAll.push(createNewPriorityLevel(listOfUrgency))
                promiseAll.push(createNewPriorityLevel(listOfTagPriority))
                Promise.all(promiseAll).then((res: Array<PriorityLevel[]>) => {
                    const newImpact = res[0]
                    const newUrgency = res[1]
                    const newPriority = res[2]
                    if (JSON.stringify(priorityMatrixObj) !== JSON.stringify(originMatrix)) {
                        priorityMatrixObj.forEach((matrix) => {
                            if (matrix.impact.id === 0 || matrix.impact.id === undefined) {
                                matrix.impact.id = newImpact.find(n => n.name === matrix.impact.name)?.id
                            }
                            if (matrix.urgency.id === 0 || matrix.urgency.id === undefined) {
                                matrix.urgency.id = newUrgency.find(n => n.name === matrix.urgency.name)?.id
                            }
                            if (matrix.priority.id === 0 || matrix.priority.id === undefined) {
                                matrix.priority.id = newPriority.find(n => n.name === matrix.priority.name)?.id
                            }
                            matrix.priorityVersion = matrix.priorityVersion + 1
                        })
                        props.createPriorityMatrixNewVersion(priorityMatrixObj)
                            .then(() => {
                                setIsLoading(false)
                                message.success('You have successfully saved the data.')
                                setIsRedirect(true)
                            })
                            .catch((err) => {
                                setIsLoading(false)
                                message.error(`You have unSuccessfully save the data. ${err}`)
                            })
                    } else {
                        setIsRedirect(true)
                        setIsLoading(false)
                        message.success('You have successfully saved the data.')
                    }
                })
            }
        })
    }

    const modalConfirmCancel = (): void => {
        setIsRequirePriority(false)
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                window.location.href = '/Setting'
            }
        })
    }

    const validateTagPriority = (_: any, value: any, callback) => {
        setIsRequirePriority(false)
        const existPriority = listOfTagPriority.map(it => it.name)
        if (value) {
            if (countIncident !== 0) {
                callback()
            } else if (existPriority.includes(value.trim()) && value.length >= 3) {
                callback('The priority is already exist.')
            } else {
                const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
                const pattern = /[A-Za-z0-9ก-๙]+/
                if (format.test(value.trim().charAt(0))) {
                    // eslint-disable-next-line
                    callback(`Enter more than 1 letter and can not contain any of the following characters ${format}`)
                } else {
                    if (pattern.test(value.trimEnd())) {
                        callback()
                    } else {
                        callback('Default value is not a valid')
                    }
                }
                callback()
            }
        } else {
            callback()
        }
    }

    const onSubmitChangePriorityColor = () => {
        setShowModal(false)
        listOfTagPriority.forEach((it) => {
            if (it.name === selectedPriorityTag) {
                it.tagColor = tagColor
            }
        })
    }

    const hasLowerCaseAndDuplicates = (arr) => {
        const lowercaseArr = arr.map(str => str.toLowerCase())
        const uniqueValues = new Set(lowercaseArr)
        return uniqueValues.size !== lowercaseArr.length
    }

    const validateImpact = (_: any, value: any, callback) => {
        if (value) {
            let isOneChar = false
            let isCharStartSpecial = false
            const reg = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            value.forEach(data => {
                if (data.length <= 1) {
                    isOneChar = true
                } else if (reg.test(data.charAt(0))) {
                    isCharStartSpecial = true
                }
            })
            if (isOneChar) {
                callback(`Field Label cant contain any of the following characters ${reg}`)
            } else if (hasLowerCaseAndDuplicates(value)) {
                callback('Impact is Duplicates')
            } else if (isCharStartSpecial) {
                callback(`Enter more than 1 letter and can not contain any of the following characters ${reg}`)
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const validateUrgency = (_: any, value: any, callback) => {
        if (value) {
            let isOneChar = false
            let isCharStartSpecial = false
            const reg = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            value.forEach(data => {
                if (data.length <= 1) {
                    isOneChar = true
                } else if (reg.test(data.charAt(0))) {
                    isCharStartSpecial = true
                }
            })
            if (isOneChar) {
                callback(`Field Label cant contain any of the following characters ${reg}`)
            } else if (hasLowerCaseAndDuplicates(value)) {
                callback('Urgency is Duplicates')
            } else if (isCharStartSpecial) {
                callback(`Enter more than 1 letter and can not contain any of the following characters ${reg}`)
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    return (
        <Spin spinning={isLoadingCountIncident}>
            {isRedirect ? (<Redirect to="/Setting" />) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Priority</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card style={{ overflow: 'auto' }}>
                <Row style={{ minWidth: 768 }}>
                    <h3 className="main-title">Priority</h3>
                    <p>Configure the matrix to automate Incident Priority assignment based on Urgency and Impact.</p>
                    <Col span={10} lg={10} md={10} sm={24} xs={24}
                        style={{ backgroundColor: '#FFF2E8', padding: '20px', marginRight: '10px' }}>
                        <Row>
                            <h3 className="sub-title">Priority Setting</h3>
                            <Col span={24}>
                                <Form.Item label={'Impact'}>
                                    {getFieldDecorator('Impact', {
                                        rules: [
                                            { required: true, message: 'Impact is required', type: 'array' },
                                            {
                                                validator: validateImpact
                                            }
                                        ]
                                    })(
                                        <Select mode="tags"
                                            placeholder="Input your impact"
                                            style={{ width: '100%' }}
                                            onChange={(value => onImpactChanged(value))}
                                            disabled={Number(countIncident) !== 0}
                                            onFocus={() => setIsRequirePriority(false)}
                                        >
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label={'Urgency'}>
                                    {getFieldDecorator('Urgency', {
                                        rules: [
                                            { required: true, message: 'Urgency is required', type: 'array' },
                                            {
                                                validator: validateUrgency
                                            }
                                        ]
                                    })(
                                        <Select mode="tags"
                                            onChange={onUrgencyChanged}
                                            onFocus={() => setIsRequirePriority(false)}
                                            placeholder="Input your urgency"
                                            disabled={Number(countIncident) !== 0}
                                            style={{ width: '100%' }}>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form>
                                    <Form.Item label="Priority">
                                        {getFieldDecorator('priority', {
                                            rules: [{
                                                required: listOfTagPriority.length === 0,
                                                message: 'Priority is required'
                                            },
                                            { validator: validateTagPriority }
                                            ]
                                        })(<Input placeholder="Input your priority"
                                            style={{ width: '100%' }}
                                            maxLength={15}
                                            disabled={Number(countIncident) !== 0}
                                            className={isRequirePriority ? 'inputRequire' : undefined} />)}
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                        {isRequirePriority ? (
                            <p style={{ color: 'red', fontSize: '13px' }}>Priority is required</p>) : null}
                        <Row>
                            <Col span={24} style={{ marginTop: '5px', overflow: 'auto' }}>
                                <CompactPicker
                                    color={tagColor}
                                    disabled={Number(countIncident) !== 0}
                                    onChangeComplete={(color) => setTagColor(color.hex)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ marginTop: '10px', textAlign: 'right' }}>
                                <Button type="primary" htmlType="submit" onClick={handleAddPriority}
                                    disabled={Number(countIncident) !== 0}
                                >Add Priority</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ marginTop: '40px' }}>
                                <fieldset style={{ padding: 10, borderRadius: 10, borderColor: '#B3B3B3' }}>
                                    <legend id='my-legend' className={'sub-title'} style={{ paddingLeft: 10, paddingRight: 10 }}> Display Order </legend>
                                    {showTag ? (
                                        renderPriorityTag()
                                    ) : null}
                                </fieldset>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={13} style={{ padding: '20px' }} lg={13} md={13} sm={24} xs={24}>
                        <h3 className="sub-title">Priority Matrix</h3>
                        <hr />
                        <Row style={{ textAlign: 'center' }}>
                            <Col span={8}><h3 className={'ant-table-column-title'}>Impact</h3></Col>
                            <Col span={8}><h3 className={'ant-table-column-title'}>Urgency</h3></Col>
                            <Col span={8}><h3 className={'ant-table-column-title'}>Priority</h3></Col>
                        </Row>
                        <hr />
                        {tempPriorityMatrix.length !== 0 ? (
                            tempPriorityMatrix.map((priorityMatrix, index) => {
                                return (
                                    <Row style={{ paddingBottom: '5px' }} key={index}>
                                        <Col span={8}><p style={{
                                            marginLeft: '95px',
                                            marginTop: '10px'
                                        }}>{priorityMatrix.impact?.name}</p></Col>
                                        <Col span={8}><p style={{
                                            marginLeft: '90px',
                                            marginTop: '10px'
                                        }}>{priorityMatrix.urgency?.name}</p>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item style={{ marginLeft: '40px' }}>
                                                {getFieldDecorator(`${priorityMatrix.urgency?.name}priority${priorityMatrix.impact?.name}`, {
                                                    initialValue: priorityMatrix.priority?.name || undefined,
                                                    rules: [{ required: true, message: 'Priority is required' }]
                                                })(
                                                    <Select placeholder={'Select priority'}
                                                        style={{ width: '70%' }}
                                                        onChange={(e) => generateMatrixToCreate(priorityMatrix.impact, priorityMatrix.urgency, e)}
                                                        onFocus={() => setIsRequirePriority(false)}
                                                    >
                                                        {listOfTagPriority.map((it, index) => {
                                                            return <Option value={it.id ? it.id as number : it.name}
                                                                key={index}>{it.name}</Option>
                                                        })}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            })
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )
                        }
                        {/* modal change priority color */}
                        <Modal
                            title="Priority Color"
                            onCancel={() => setShowModal(!showModal)}
                            visible={showModal}
                            width={'400px'}
                            footer={[
                                <Button key="back" onClick={() => setShowModal(!showModal)}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" onClick={onSubmitChangePriorityColor}>
                                    Save
                                </Button>
                            ]}
                        >
                            <Form onSubmit={onSubmitChangePriorityColor}>
                                <Row style={{ textAlign: 'center', overflow: 'auto' }}>
                                    <CompactPicker
                                        color={tagColor}
                                        className={'customWidth'}
                                        disabled={countIncident !== 0}
                                        onChangeComplete={(color) => setTagColor(color.hex)}
                                    />
                                </Row>
                                <Row style={{ marginTop: '10px', marginLeft: '53px' }}>
                                    <Tag color={tagColor}>{selectedPriorityTag}</Tag>
                                </Row>
                            </Form>
                        </Modal>
                        < hr />
                    </Col>
                </Row>
                <Row>
                    <div style={{ float: 'right', marginTop: '100px' }}>
                        <Form.Item>
                            <Button type="default" htmlType="button"
                                style={{ marginRight: 10 }} onClick={() => modalConfirmCancel()}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" loading={isLoading}
                                onClick={handleSubmitTable}>
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </Row>
            </Card>
        </Spin>
    )
}

const PriorityConfigurationPage = Form.create(
    {}
)(PriorityConfigurationForm)

export default connect(mapStateToProps,
    {
        createPriorityMatrixNewVersion,
        getPriorityConfiguration,
        // getAllIncident,
        getAllPriority,
        getAllImpact,
        getAllUrgency
    }
)(PriorityConfigurationPage)
