/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Drawer, Dropdown, Icon, Input, Menu, message, Modal, Row, Spin, Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { Knowledge, KnowledgeList, KnowledgeStatus } from './model'
import { Category } from '../category'
import { Folder } from '../folder'
import { convertCamelCaseToSentenceCase } from '../util'
import { KnowledgeListColumn } from './KnowledgeListColumn'
import CategoryForm from '../category/CategoryForm'
import { ClickParam } from 'antd/lib/menu'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { checkDeletePermission } from './service'
import ChatUI from '../ChatGPT/ChatUI'
import { tokenDecode } from '../../common-misc'

const config = require('./config.json')
const { confirm } = Modal
interface Params {
    knowledge: KnowledgeList
    title: string,
    description: string,
    category?: Category
    folder?: Folder
    isFetch: boolean
    kmRole: string[]
    fetchKm: Function,
    deleteKm: Function,
    deleteFolder: Function,
    deleteCategory: Function,
    setSearchInput: Function,
    searchStatus: string,
    searchInput: string
}

// interface HeaderDetail {
//     title: string
//     description: string
// }

type Props = Params
const KnowledgeListView: React.FC<Props> = (props: Props) => {
    const [title, setTitle] = useState<string>(props.title)
    const [description, setDescription] = useState<string>(props.description)
    const [stateVisibleFrom, setStateVisibleFrom] = useState<boolean>(false)
    const [stateIsCreateCategory, setStateIsCreateCategory] = useState<boolean>(true)
    const [editData, setEditData] = useState<any>()
    const [isShowChatGPT, setIsShowChatGPT] = useState<boolean>(false)
    const [isShowButtonChatGPT, setIsShowButtonChatGPT] = useState<boolean>(false)
    const [getSearch, setSearch] = useState<string>('')
    // const [searchStatus, setSearchStatus] = useState<string>('AllStatus')
    // const [searchInput, props.setSearchInput] = useState<string>('')
    const pageSize = config.pageSize
    let searchTimeOut: NodeJS.Timeout
    const username = sessionStorage.getItem('username')
    const ChatGPTKey = tokenDecode()?.chatGptKey || ''
    const currentPage: number = Number(sessionStorage.getItem('km_current_page')) || 1

    useEffect(() => {
        setTitle(props.title)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.title])

    useEffect(() => {
        setDescription(props.description)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.description])

    const { Search } = Input

    const handleClick = (e: ClickParam) => {
        props.fetchKm(props.category?.id, props.folder?.id, e.key)
    }

    const handleOnClickPageNumber = (page: number) => {
        sessionStorage.setItem('km_current_page', page.toString())
        props.fetchKm(props.category?.id, props.folder?.id)
    }

    const inputSearch = (e) => {
        clearTimeout(searchTimeOut)
        props.setSearchInput(e.target.value)
        setSearch(e.target.value)
    }

    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="AllStatus">
                {convertCamelCaseToSentenceCase('AllStatus')}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.MyNote}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.MyNote)}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.Draft}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.Draft)}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.AwaitingApproval}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.AwaitingApproval)}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.AwaitingRetire}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.AwaitingRetire)}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.Published}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.Published)}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.Rejected}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.Rejected)}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.Retired}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.Retired)}
            </Menu.Item>
        </Menu>
    )

    const deleteKM = (knowledgeId, status, ticketNumber) => {
        const isRetireStatus = status === KnowledgeStatus.Retired
        const deleteKmFunction = props.deleteKm(isRetireStatus)
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete of this data ?',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                // รอ delete km
                deleteKmFunction(isRetireStatus ? ticketNumber : knowledgeId).then(() => {
                    message.success('Delete Knowledge Success')
                    props.fetchKm(props.category?.id, props.folder?.id)
                }).catch(() => {
                    message.error('Failed to delete Knowledge')
                })
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const columns = [
        {
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <KnowledgeListColumn rowData={row} deleteKM={deleteKM} hasPermission={checkPermissionDelete} />
            ),
            key: 'id'
        }
    ]

    function openDrawer(e) {
        switch (e.key) {
        case 'Category':
            setStateVisibleFrom(true)
            setStateIsCreateCategory(true)
            setEditData(undefined)
            break
        case 'Folder':
            setStateVisibleFrom(true)
            setStateIsCreateCategory(false)
            setEditData(undefined)
            break
        }
    }

    function openEditDrawer(type: string, data: any) {
        switch (type) {
        case 'Category':
            setStateVisibleFrom(true)
            setStateIsCreateCategory(true)
            setEditData(data)
            break
        case 'Folder':
            setStateVisibleFrom(true)
            setStateIsCreateCategory(false)
            setEditData(data)
            break
        }
    }

    const closeDrawer = () => {
        setStateVisibleFrom(false)
    }

    const checkPermissionEdit = (folder: Folder): boolean => {
        if (folder?.managedBy.length !== 0) {
            if (!folder?.managedBy?.some(it => props.kmRole.includes(it.groupName))) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const checkPermissionDelete = (folderId: number, data: Knowledge): boolean => {
        const folder = props.category?.folderList?.find(it => it.id?.toString() === folderId.toString())
        const isCreatedBy = data.createdBy === username
        const isCanDelete = checkDeletePermission()
        if (folder) {
            if (isCreatedBy) {
                return true
            } else {
                if (isCanDelete) {
                    return true
                } else {
                    return false
                }
            }
        } else {
            return false
        }
    }

    // @ts-ignore
    const createMenu = (
        <div>
            {checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle') ? (
                <Menu onClick={openDrawer}>
                    <Menu.Item key="Category">
                        <span className='km-font-menu '>Category</span>
                    </Menu.Item>
                    <Menu.Item key="Folder">
                        <span className='km-font-menu '>Folder</span>
                    </Menu.Item>
                    <Menu.Item key="Knowledge" disabled={checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle') ? false : checkPermissionEdit(props.folder!!)}>
                        {props.folder?.id ? (
                            <Link to={`/KnowledgeForm/${props.category?.id}/${props.folder?.id}`} >
                                <span className='km-font-menu '>Knowledge</span>
                            </Link>
                        ) : props.category?.id ? (
                            <Link to={`/KnowledgeForm/${props.category?.id}`} >
                                <span className='km-font-menu '>Knowledge</span>
                            </Link>
                        ) : (
                            <Link to='/KnowledgeForm'>
                                <span className='km-font-menu '>Knowledge</span>
                            </Link>
                        )}
                    </Menu.Item>
                </Menu>
            ) : (
                <Menu onClick={openDrawer}>
                    <Menu.Item key="Category" disabled>
                        <span>Category</span>
                    </Menu.Item>
                    <Menu.Item key="Folder" disabled>
                        <span>Folder</span>
                    </Menu.Item>
                    <Menu.Item key="Knowledge" disabled={props.folder?.managedBy.length === 0 ? false : checkPermissionEdit(props.folder!!)}>
                        {props.folder?.id ? (
                            <Link to={`/KnowledgeForm/${props.category?.id}/${props.folder?.id}`} >
                                <span className={(props.folder?.managedBy.length === 0 ? false : checkPermissionEdit(props.folder!!)) ? '' : 'km-font-menu '} >Knowledge</span>
                            </Link>
                        ) : props.category?.id ? (
                            <Link to={`/KnowledgeForm/${props.category?.id}`} >
                                <span className={(props.folder?.managedBy.length === 0 ? false : checkPermissionEdit(props.folder!!)) ? '' : 'km-font-menu '} >Knowledge</span>
                            </Link>
                        ) : (
                            <Link to='/KnowledgeForm'>
                                <span className={(props.folder?.managedBy.length === 0 ? false : checkPermissionEdit(props.folder!!)) ? '' : 'km-font-menu '} >Knowledge</span>
                            </Link>
                        )}
                    </Menu.Item>
                </Menu>
            )}
        </div>
    )

    const editCategoryTools = () => {
        if (props.folder?.id) {
            if (props.folder?.id !== 'all') {
                return (
                    <Tooltip placement="bottom" title={'Edit'}>
                        <Icon className="edit_icon" type="edit" style={{ color: 'black', marginLeft: 4 }}
                            onClick={() => {
                                openEditDrawer('Folder', props.folder)
                            }} />
                    </Tooltip>
                )
            }
        } else if (props.category?.id) {
            return (
                <Tooltip placement="bottom" title={'Edit'}>
                    <Icon className="edit_icon" type="edit" style={{ color: 'black', marginLeft: 4 }}
                        onClick={() => {
                            openEditDrawer('Category', props.category)
                        }} />
                </Tooltip>
            )
        }
    }

    const deleteCategoryTools = () => {
        if (props.folder?.id) {
            if (props.folder?.id !== 'all') {
                return (
                    <Tooltip placement="bottom" title={'Delete'}>
                        <Icon className="edit_icon" type="delete" style={{ color: 'black', marginLeft: 4 }}
                            onClick={() => {
                                // openEditDrawer('Folder', props.folder)
                                CheckDeleteFolder(props.folder?.id!!)
                            }} />
                    </Tooltip>
                )
            }
        } else if (props.category?.id) {
            return (
                <Tooltip placement="bottom" title={'Delete'}>
                    <Icon className="edit_icon" type="delete" style={{ color: 'black', marginLeft: 4 }}
                        onClick={() => {
                            // openEditDrawer('Category', props.category)
                            if (props.category?.folderList?.length === 0) {
                                CheckDeleteCategory(props.category?.id)
                            } else {
                                Modal.warning({
                                    title: 'Warning',
                                    content: 'Please delete all folders.'
                                })
                            }
                        }} />
                </Tooltip>
            )
        }
    }

    const CheckDeleteFolder = (folderId: string | undefined) => {
        confirm({
            title: 'Are you sure?',
            content: 'When a folder is deleted, all KM article in it is permanently erased. Do you confirm Delete of this data?',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                props.deleteFolder(Number(folderId!!))
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const CheckDeleteCategory = (categoryId: string | undefined) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete of this data ?',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                // รอ delete km
                props.deleteCategory(Number(categoryId!!))
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const drawerTitle = () => {
        if (editData) {
            return stateIsCreateCategory ? 'Edit Category' : 'Edit Folder'
        }
        return stateIsCreateCategory ? 'Create Category' : 'Create Folder'
    }

    const setEditedTitle = (data) => {
        setTitle(data)
    }

    const handleSearchKM = (value) => {
        props.fetchKm(props.category?.id, props.folder?.id, undefined, value.trim())
        if (ChatGPTKey !== '' && value.trim() !== '') {
            setIsShowButtonChatGPT(true)
        } else {
            setIsShowButtonChatGPT(false)
        }
        props.setSearchInput(value.trim())
    }

    return (
        <Spin tip="Loading..." spinning={props.isFetch}>
            <Drawer
                title={drawerTitle()}
                width={window.innerWidth < 768 ? 'auto' : 720}
                onClose={closeDrawer}
                visible={stateVisibleFrom}
                bodyStyle={{ paddingBottom: 80, overflow: 'auto' }}
            >
                <CategoryForm
                    isCategoryForm={stateIsCreateCategory}
                    isFormVisible={stateVisibleFrom}
                    editData={editData}
                    setEditedTitle={setEditedTitle}
                    closeForm={closeDrawer}
                    categoryName={props.category?.id}
                    kmRole={props.kmRole || []}
                />
            </Drawer>
            <Card className='card-height-default'>
                <Row style={{ display: 'flex', justifyContent: 'end' }} gutter={[16, 16]}>
                    {isShowButtonChatGPT ? <Col span={21} style={{ display: 'flex', justifyContent: 'end', paddingTop: 0 }}>
                        <Button onClick={() => setIsShowChatGPT(true)}>
                            Search by ChatGPT
                        </Button>
                    </Col> : null}
                    <Col span={isShowButtonChatGPT ? 3 : 24} style={{ display: 'contents' }}>
                        <Dropdown overlay={createMenu} trigger={['click']}>
                            <Button type='primary' >
                                Add new
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <h2 className="main-title">
                        {`${title} (${props.knowledge.pagination?.totalElements || 0}) `}
                        {editCategoryTools()}
                        {checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle') ? deleteCategoryTools() : null}
                        {window.innerWidth < 768 ? (
                            <Col span={24}>
                                <Dropdown overlay={menu} trigger={['click']} >
                                    <span className="feck-link ant-dropdown-link" style={{ fontSize: 16 }} onClick={e => e.preventDefault()}>
                                        {convertCamelCaseToSentenceCase(props.searchStatus)} <Icon type="down" />
                                    </span>
                                </Dropdown>
                            </Col>
                        ) : (
                            <Dropdown overlay={menu} trigger={['click']} className='spacing'>
                                <span className="feck-link ant-dropdown-link" style={{ fontSize: 16 }} onClick={e => e.preventDefault()}>
                                    {convertCamelCaseToSentenceCase(props.searchStatus)} <Icon type="down" />
                                </span>
                            </Dropdown>
                        )}
                    </h2>
                </Row>
                <Row>
                    <div>{description}</div>
                    <br />
                </Row>
                <Row>
                    <Search
                        placeholder="Can search Topic, Detail, Tag, ID and Created By"
                        onChange={inputSearch}
                        value={props.searchInput}
                        style={{ minWidth: 200 }}
                        id='search_knowledge'
                        onSearch={handleSearchKM}
                        allowClear
                    />
                </Row>
                <br />
                <Row >
                    <div style={{ overflowX: 'auto' }}>
                        <Table
                            className="table-configherder table-noherder fix_scroll_md"
                            dataSource={props.knowledge.content}
                            columns={columns}
                            rowKey="id"
                            pagination={{
                                pageSize: pageSize,
                                current: currentPage,
                                total: props.knowledge.pagination?.totalElements,
                                onChange: (event) => { handleOnClickPageNumber(event) }
                            }} />
                    </div>
                </Row>
            </Card>
            <Modal
                title="ChatGPT"
                visible={isShowChatGPT}
                footer={null}
                closable={false}
                width={800}
            >
                <ChatUI setOpen={setIsShowChatGPT} initText={getSearch} />
            </Modal>
        </Spin>

    )
}

export default KnowledgeListView
